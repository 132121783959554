export default {
  dr: require('../assets/images/Dr.jpeg'),
  IconMyChannel: require('../assets/images/IconMyChannel.png'),
  IconSubscriptions: require('../assets/images/IconSubscriptions.png'),
  IconPaymentMethods: require('../assets/images/IconPaymentMethods.png'),
  IconProfile: require('../assets/images/IconProfile.png'),
  IconNotfications: require('../assets/images/IconNotfications.png'),
  IconPreferences: require('../assets/images/IconPreferences.png'),
  IconSecurity: require('../assets/images/IconSecurity.png'),
  IconLanguages: require('../assets/images/IconLanguages.png'),
  IconDarkMode: require('../assets/images/IconDarkMode.png'),
  IconHelp: require('../assets/images/IconHelp.png'),
  IconInvite: require('../assets/images/IconInvite.png'),
  IconCommunity: require('../assets/images/IconCommunity.png'),
  IconTerms: require('../assets/images/IconTerms.png'),
  IconAbout: require('../assets/images/IconAbout.png'),
  IconLogout: require('../assets/images/IconLogout.png'),
  sms: require('../assets/images/Sms.png'),
  chae: require('../assets/images/Chae.jpg'),
  inter: require('../assets/images/Inter.png'),
  Pin: require('../assets/images/Pin.png'),
  Avatar: require('../assets/images/Avatar.jpeg'),
  CharlieGreen3JmfENcL24MUnsplash: require('../assets/images/CharlieGreen3JmfENcL24MUnsplash.jpg'),
  facebook: require('../assets/images/Facebook.png'),
  apple: require('../assets/images/Apple.png'),
  google: require('../assets/images/Google.png'),
  email: require('../assets/images/Email.png'),
  Avatar2: require('../assets/images/Avatar2.png'),
  boooks: require('../assets/images/Boooks.jpeg'),
  everest: require('../assets/images/Everest.jpg'),
  JoelMottLaK153ghdigUnsplash: require('../assets/images/JoelMottLaK153ghdigUnsplash.jpg'),
  seandon: require('../assets/images/SeanDon.jpg'),
  Boy: require('../assets/images/Boy.png'),
  Like: require('../assets/images/Like.png'),
  Girl: require('../assets/images/Girl.png'),
  User: require('../assets/images/User.jpeg'),
  Ellipse21: require('../assets/images/Ellipse21.png'),
  EditPicFrame: require('../assets/images/EditPicFrame.png'),
  MalikSkydsgaardIgkwHPbVk8Unsplash: require('../assets/images/MalikSkydsgaardIgkwHPbVk8Unsplash.jpg'),
  LolaRoseZcHseJqAeEcUnsplash: require('../assets/images/LolaRoseZcHseJqAeEcUnsplash.jpg'),
  Medicines: require('../assets/images/Medicines.png'),
  Calendar: require('../assets/images/Calendar.png'),
  EditProfile: require('../assets/images/EditProfile.png'),
  Image: require('../assets/images/Image.png'),
  Camera: require('../assets/images/Camera.png'),
  Done: require('../assets/images/Done.png'),
  UserImage: require('../assets/images/UserImage.png'),
  Edit: require('../assets/images/Edit.png'),
  Check: require('../assets/images/Check.png'),
  ScreenShot20211123At120916PM: require('../assets/images/ScreenShot20211123At120916PM.png'),
  Guac: require('../assets/images/Guac.jpg'),
  Ramen: require('../assets/images/Ramen.jpg'),
  Games: require('../assets/images/Games.png'),
  Video: require('../assets/images/Video.png'),
  Logo: require('../assets/images/Logo.png'),
  Notifications: require('../assets/images/Notifications.png'),
  LastStream: require('../assets/images/LastStream.png'),
  JonathanBorbaKgCSRo4SiT8Unsplash: require('../assets/images/JonathanBorbaKgCSRo4SiT8Unsplash.jpg'),
  AndreTaissin5OUMf1Mr5pUUnsplash: require('../assets/images/AndreTaissin5OUMf1Mr5pUUnsplash.jpg'),
  img: require('../assets/images/Img.png'),
};
